<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/finance_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 第一模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.firstModule.title">
			<div class="title fontsize30">{{dataObj.firstModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.firstModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 第2模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.secondModule.title">
			<div class="title fontsize30">{{dataObj.secondModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.secondModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 第3模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.thirdModule.title">
			<div class="title fontsize30">{{dataObj.thirdModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.thirdModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 第4模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.fourthModule.title">
			<div class="title fontsize30">{{dataObj.fourthModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.fourthModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 第5模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.fifthModule.title">
			<div class="title fontsize30">{{dataObj.fifthModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.fifthModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 第6模块 -->
		<div class="finance_daikuan_box w1239" v-if="dataObj&&dataObj.sixthModule.title">
			<div class="title fontsize30">{{dataObj.sixthModule.title}}</div>
			<div class="desc fontsize16">
				<div v-html="dataObj.sixthModule.contents"></div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- 在线申请 -->
		<div class="huaxue_inline_apply_box" :style="'height:'+screenHeight" v-if="dataObj&&dataObj.applyFlag==0">
			<div class="bgimg">
				<!-- <img src="../assets/static/Group20400.png"/> -->
			</div>
			<div class="contitlebox">
				<div class="titlename fontsize30">在线申请</div>
				<div class="block20"></div>
				<div class="conbox fontsize16">
					<div class="inputdiv">
						<div class="label">企业名称</div>
						<div class="input"><input v-model="formObj.comName" placeholder="请输入企业名称"/></div>
					</div>
					<div class="inputdiv">
						<div class="label afterstar">联系人</div>
						<div class="input"><input v-model="formObj.name" placeholder="请输入联系人姓名"/></div>
					</div>
					<div class="inputdiv">
						<div class="label afterstar">电话</div>
						<div class="input"><input v-model="formObj.phone" placeholder="请输入联系人电话"/></div>
					</div>
					<div class="block20"></div>
					<div class="btndiv fontsize14" @click="handleApply">立即申请</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			screenHeight:"430px",
			type:"",
			typeName:"",
			dataObj:null,
			formObj:{
				comName:"",
				name:"",
				phone:""
			},
			bannerObj:{}
		};
	},
	mounted: function() {
		console.log(this.$route)
		//获取，宽度百分百，高度是多少
		if(document.documentElement.clientWidth>1920){
			window.onresize = () => {
			  return (() => {
			    this.screenHeight = (document.documentElement.clientWidth * 430) / 1920 + "px";
			  })();
			};
		}
		var type = 7
		//判断来源
		if(this.$route.query&&this.$route.query.type){
			this.type = this.$route.query.type
			if(this.$route.query.typeName){
				this.typeName = this.$route.query.typeName
			}
			if(this.type=="baoxian"){
				type = 8
			}
			if(this.type=="tourongzi"){
				type = 9
			}
			if(this.type=="shangshifudao"){
				type = 10
			}
			this.getdataObj()
		}else{
			//this.$util.routerPath("/404")
		}
		//头图
		this.getBannerFuc(type).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取数据
		getdataObj() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 1,
			};
			var controller = ""
			if(this.type=="ziyarongzi"){
				//知产质押融资
				controller = "frontEnd/transaction/ziyarongzi"
			}else if(this.type=="baoxian"){
				//知产保险
				controller = "frontEnd/transaction/baoxian"
			}else if(this.type=="tourongzi"){
				//知产投融资
				controller = "frontEnd/transaction/tourongzi"
			}else if(this.type=="shangshifudao"){
				//科创板上市辅导
				controller = "frontEnd/transaction/shangshifudao"
			}else{}
			this.$http.post(controller, params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataObj = dataobj
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//提交申请
		handleApply(){
			var _this = this;
			if (!this.formObj.name || !this.formObj.phone) {
			  this.$message.error('联系人和手机电话不能为空')
			  return false
			}
			var params = this.formObj
			params["type"] = this.type
			this.$http.post("frontEnd/transaction/applyOnline", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$alert('提交申请成功，切勿重复提交！', '提示', {confirmButtonText: '确定'});
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
